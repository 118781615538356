// Core
import { makeStyles } from "@material-ui/core/styles";

const useGlobalStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export { useGlobalStyles };
