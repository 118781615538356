// Core
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { getWavs, searchByAudio } from "redux/actions/audio-actions";

// Components
import {
  Catcher,
  MuiWrapper,
  MuiLoader,
  MuiTableContainer,
  MuiSearch,
} from "components/common";
import { Typography, Paper } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { columns } from "./table";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { ReactComponent as AudioIcon } from "theme/icons/audio.svg";

// Styles
import { useStyles } from "./audios.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const Audios = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();
  const searchByAudioValue = useSelector((state) => state.audio.searchByAudio);
  const [audios, setAudios] = useState(null);

  const { isLoading, isError, error } = useQuery(
    ["audios"],
    () => dispatch(getWavs()),
    {
      onSuccess: (data) => {
        setAudios(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <MuiWrapper maxWidth="lg" isMobile={isMobile}>
        <div
          className={clsx(
            classes.titleRow,
            isMobile ? globalClasses.container : ""
          )}
        >
          <AudioIcon className={classes.titleIcon} />
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.title}
          >
            Audios ({audios?.length})
          </Typography>
        </div>

        {audios && (
          <div className={classes.table}>
            <Paper className={classes.tableWrapper}>
              <div className={classes.searchRow}>
                <MuiSearch
                  className={classes.search}
                  placeholder="Search"
                  value={searchByAudioValue}
                  onChange={(audio) => dispatch(searchByAudio(audio))}
                />
              </div>

              <MuiTableContainer
                tableData={audios}
                tableColumns={columns}
                initialState={{
                  sortBy: [{ id: "wav", desc: false }],
                }}
                searchValue={searchByAudioValue}
                searchFields={["wav"]}
              />
            </Paper>
          </div>
        )}
      </MuiWrapper>
    </Catcher>
  );
};

export default Audios;
