// Types
import * as types from "redux/actiontypes/ui-types";

const initialState = {
  snackbarIsOpen: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        snackbarIsOpen: action.payload,
      };

    default:
      return state;
  }
};

export { uiReducer };
