// Core
import React, { useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

// Components
import { TextField, InputAdornment, IconButton } from "@material-ui/core";

// Icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

// Styles
import { useStyles } from "./input.styles";

const MuiInput = ({ label, name, type, ...rest }) => {
  const classes = useStyles();
  const { touched, errors } = useFormikContext();
  const [revealPassword, setRevealPassword] = useState(false);

  const togglePassword = () => setRevealPassword(!revealPassword);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const icon =
    type === "password" ? (
      <div className={classes.icon}>
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={togglePassword}
            onMouseDown={handleMouseDownPassword}
            tabIndex="-1"
          >
            {revealPassword ? (
              <Visibility fontSize="small" />
            ) : (
              <VisibilityOff fontSize="small" />
            )}
          </IconButton>
        </InputAdornment>
      </div>
    ) : null;

  return (
    <Field
      className={classes.input}
      as={TextField}
      id={name}
      type={type === "password" ? (revealPassword ? "text" : "password") : type}
      name={name}
      label={label}
      fullWidth
      variant="outlined"
      margin="normal"
      helperText={<ErrorMessage name={name} />}
      error={touched[name] && Boolean(errors[name])}
      InputProps={{
        endAdornment: icon,
      }}
      {...rest}
    />
  );
};

MuiInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MuiInput;
