// Core
import React, { Component } from "react";

// Styles
import styles from "./catcher.module.scss";

class Catcher extends Component {
  state = {
    error: false,
  };

  componentDidCatch(error, stack) {
    console.log("ERROR:", error.message);
    console.log("STACKTRACE:", stack.componentStack);

    this.setState({
      error: true,
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <section className={styles.catcher}>
          <p>Something went wrong!</p>
        </section>
      );
    }

    return children;
  }
}

export default Catcher;
