// Core
import React from "react";

// Components
import { Typography, Link } from "@material-ui/core";

// Styles
import { useStyles } from "./copyright.styles";

const MuiCopyright = () => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.root}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://outloud.ai/">
        outloud.ai
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

export default MuiCopyright;
