// Core
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import moment from "moment-timezone";

// Actions
import { showSnackbar } from "redux/actions/ui-actions";

// Components
import {
  Slider,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Link,
} from "@material-ui/core";

// Icons
import { PlayCircleFilled, Pause, GetApp } from "@material-ui/icons";
import { ReactComponent as RewindIcon } from "theme/icons/rewind.svg";
import { ReactComponent as ForwardIcon } from "theme/icons/forward.svg";
import { ReactComponent as VolumeIcon } from "theme/icons/volume.svg";
import { ReactComponent as SpeedIcon } from "theme/icons/speed.svg";
import { ReactComponent as ShareIcon } from "theme/icons/share.svg";

// Tools
import { secToMinutes } from "utils/helpers";

// Styles
import { useStyles } from "./waveplayer.styles";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
let context, processor, backend;

const waveSurferOptions = (ref) => ({
  container: ref,
  backend: backend || "MediaElement",
  mediaControls: false,
  audioContext: context || null,
  audioScriptProcessor: processor || null,
  waveColor: "#d1d1d1",
  progressColor: "#4677df",
  cursorColor: "#4677df",
  barWidth: 3,
  barMinHeight: 5,
  barRadius: 3,
  responsive: true,
  height: 100,
  audioRate: 1,
  skipLength: 5,
  normalize: true,
});

WaveSurfer.timeline = TimelinePlugin;
WaveSurfer.regions = RegionsPlugin;

const WavePlayer = ({ url, timestamp }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const wavePlayerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const downloadLink = useRef(null);
  const { id } = useParams();
  const wavePlayerTimeline = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(
    Number(localStorage.getItem("volume")) || 0.5
  );
  const [speedRate, setSpeedRate] = useState(
    Number(localStorage.getItem("speedRate")) || 1
  );
  const [speedOpen, setSpeedOpen] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const speedValues = [0.75, 1, 1.25, 1.5];

  useEffect(() => {
    if (isSafari) {
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      context = new AudioContext();
      processor = context.createScriptProcessor(1024, 1, 1);
      backend = "WebAudio";
    }

    const options = waveSurferOptions(wavePlayerRef.current);

    const updateTime = (ref) => {
      const totalTime = ref.getDuration();
      const currentTime = ref.getCurrentTime();
      setTotalTime(secToMinutes(totalTime));
      setCurrentTime(secToMinutes(currentTime));
    };

    waveSurferRef.current = WaveSurfer.create({
      ...options,
      plugins: [
        WaveSurfer.timeline.create({
          container: wavePlayerTimeline.current,
          primaryFontColor: "#444851",
          secondaryFontColor: "#444851",
        }),
      ],
    });

    setPlaying(false);

    waveSurferRef.current.load(url);

    waveSurferRef.current.on("ready", function () {
      updateTime(waveSurferRef.current);
      waveSurferRef.current.setPlaybackRate(
        Number(localStorage.getItem("speedRate")) || 1
      );
      waveSurferRef.current.setVolume(
        Number(localStorage.getItem("volume")) || 0.5
      );
    });

    waveSurferRef.current.on("finish", function () {
      setPlaying(false);
      waveSurferRef.current.pause();
    });

    waveSurferRef.current.on("seek", function () {
      updateTime(waveSurferRef.current);
    });

    waveSurferRef.current.on("audioprocess", function () {
      updateTime(waveSurferRef.current);
    });

    return () => waveSurferRef.current.destroy();
  }, [url]);

  useEffect(() => {
    setVolume(volume);
    localStorage.setItem("volume", volume);
    waveSurferRef.current.setVolume(volume);
  }, [volume]);

  const handlePlayPause = () => {
    setPlaying(!playing);
    waveSurferRef.current.playPause();
  };

  const handleRewind = () => {
    waveSurferRef.current.skipBackward();
    setCurrentTime(secToMinutes(waveSurferRef.current.getCurrentTime()));
  };

  const handleForward = () => {
    waveSurferRef.current.skipForward();
    setCurrentTime(secToMinutes(waveSurferRef.current.getCurrentTime()));

    if (
      waveSurferRef.current.getCurrentTime() >
        waveSurferRef.current.getDuration() ||
      waveSurferRef.current.getCurrentTime() === 0
    ) {
      waveSurferRef.current.stop();
      setPlaying(false);
    }
  };

  const handleSpeed = (e) => {
    waveSurferRef.current.skip(0);
    waveSurferRef.current.setPlaybackRate(e.target.value);
    setSpeedRate(Number(e.target.value));
    localStorage.setItem("speedRate", e.target.value);
    setSpeedOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    const newVolume = Number(newValue);
    if (newVolume) {
      setVolume(newVolume);
      localStorage.setItem("volume", newVolume);
      waveSurferRef.current.setVolume(newVolume || 1);
    }
  };

  const handleSpeedClose = () => setSpeedOpen(false);

  const handleSpeedOpen = () => setSpeedOpen(true);

  const handleCopyText = () => {
    dispatch(showSnackbar(true));
  };

  const handleDownloadAudio = () => {
    downloadLink.current.href = url;
    setTimeout(() => {
      downloadLink.current.href = "";
    }, 1000);
  };

  const formatDownloadAudioTitle = (id) => {
    if (timestamp) {
      const formattedAudioName = id?.split(".")[0];
      const dateParts = moment.parseZone(timestamp).format("MMDDYYYY");
      const timeParts = moment.parseZone(timestamp).format("hhmm");
      return `${formattedAudioName}_${dateParts}_${timeParts}.wav`;
    }
  };

  return (
    <section className={classes.root}>
      <div ref={wavePlayerRef} />
      <div ref={wavePlayerTimeline} className={classes.timeline} />

      <div className={classes.duration}>
        <Typography
          className={classes.time}
          variant="caption"
          color="textPrimary"
        >
          {currentTime} / {totalTime}
        </Typography>
      </div>

      <div className={classes.controls}>
        <Typography
          className={classes.currentTime}
          variant="caption"
          color="textPrimary"
        >
          {currentTime}
        </Typography>
        <Typography
          className={classes.totalTime}
          variant="caption"
          color="textPrimary"
        >
          {totalTime}
        </Typography>

        <div className={classes.row}>
          <IconButton
            className={clsx(classes.iconButton, classes.speedButton)}
            aria-label="speed"
            disableFocusRipple
            disableRipple
            onClick={handleSpeedOpen}
          >
            <SpeedIcon className={classes.speedIcon} />
            <Typography
              variant="caption"
              color="primary"
              className={classes.speedText}
            >
              Speed:
            </Typography>
          </IconButton>

          <FormControl className={classes.formControl}>
            <Select
              value={speedRate}
              defaultValue={speedRate}
              open={speedOpen}
              onChange={handleSpeed}
              onClose={handleSpeedClose}
              onOpen={handleSpeedOpen}
              className={classes.select}
            >
              {speedValues.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}x
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={clsx(classes.row, classes.playControls)}>
          <div className={classes.column}>
            <IconButton
              className={classes.iconButton}
              onClick={handleRewind}
              aria-label="rewind"
              disableFocusRipple
              disableRipple
            >
              <RewindIcon className={classes.skipIcon} />
            </IconButton>
            <Typography
              variant="caption"
              color="primary"
              className={classes.skipText}
            >
              5 sec
            </Typography>
          </div>

          <IconButton
            className={clsx(classes.iconButton, classes.playPauseIcon)}
            aria-label="play"
            onClick={handlePlayPause}
            disableFocusRipple
            disableRipple
          >
            {!playing ? (
              <PlayCircleFilled
                className={classes.playIcon}
                fontSize="large"
                color="primary"
              />
            ) : (
              <Pause
                className={classes.pauseIcon}
                fontSize="large"
                color="primary"
              />
            )}
          </IconButton>

          <div className={classes.column}>
            <Typography
              variant="caption"
              color="primary"
              className={classes.skipText}
            >
              5 sec
            </Typography>
            <IconButton
              className={classes.iconButton}
              onClick={handleForward}
              aria-label="forward"
              disableFocusRipple
              disableRipple
            >
              <ForwardIcon className={classes.skipIcon} />
            </IconButton>
          </div>
        </div>

        {isMobile && (
          <CopyToClipboard
            text={`${window.location.href}`}
            onCopy={handleCopyText}
          >
            <ShareIcon className={classes.shareIcon} />
          </CopyToClipboard>
        )}

        <div className={clsx(classes.row, classes.volume)}>
          <Typography
            variant="caption"
            color="primary"
            className={classes.volumeText}
          >
            Volume
          </Typography>
          <IconButton
            className={classes.iconButton}
            aria-label="volume"
            disableFocusRipple
            disableRipple
          >
            <VolumeIcon className={classes.volumeIcon} />
          </IconButton>
          <Slider
            className={classes.volumeSlider}
            min={0.01}
            max={1}
            step={0.025}
            defaultValue={volume}
            value={typeof volume === "number" ? volume : 0}
            onChange={handleSliderChange}
            aria-labelledby="volume-slider"
          />
        </div>
      </div>
      <div className={classes.downloadLinkWrapper}>
        <Link
          className={classes.downloadLink}
          ref={downloadLink}
          download={formatDownloadAudioTitle(id)}
          onClick={handleDownloadAudio}
          underline="always"
          rel="noopener noreferrer"
          target="_blank"
        >
          <GetApp className={classes.downloadIcon} fontSize="small" />
          Download audio
        </Link>
      </div>
    </section>
  );
};

export default WavePlayer;
