// Core
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// Components
import { MuiHeaderCell } from "components/common";
import { Link, Grid } from "@material-ui/core";

// Tools
import { getDateFromTimestamp, formatBytes } from "utils/helpers";

const columns = [
  {
    Header: () => <MuiHeaderCell title="Stream" />,
    Cell: (props) => {
      const audioId = props.row.original.wav;
      return (
        <Grid
          container
          wrap="nowrap"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Link
            component={RouterLink}
            to={`/audio/${audioId}`}
            underline="always"
          >
            {props.value}
          </Link>
        </Grid>
      );
    },
    accessor: "wav",
    width: 150,
  },
  {
    Header: () => <MuiHeaderCell title="Date" />,
    id: "date",
    accessor: (d) => getDateFromTimestamp(d.timestamp)?.date,
  },
  {
    Header: () => <MuiHeaderCell title="Time" />,
    id: "time",
    accessor: (d) => getDateFromTimestamp(d.timestamp)?.time,
  },
  {
    Header: () => <MuiHeaderCell title="Size" />,
    accessor: "size",
    Cell: ({ value }) => <span>{formatBytes(value)}</span>,
  },
];

export { columns };
