// Core
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  emptyWrapper: {
    maxHeight: 300,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflowY: "auto",
  },
  empty: {
    display: "block",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
}));

const MuiEmptySearch = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyWrapper}>
      <Typography className={classes.empty} color="textPrimary">
        {text}
      </Typography>
    </div>
  );
};

export default MuiEmptySearch;
