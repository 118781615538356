// Service
import { ApiError } from "api";
import { AudioService } from "services";

// Types
import * as types from "redux/actiontypes/audio-types";

const getWavs = () => async () => {
  try {
    const { data } = await AudioService.getWavs();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getAudio = (id) => async () => {
  try {
    const { data } = await AudioService.getAudio(id);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getWavData = (id) => async () => {
  try {
    const { data } = await AudioService.getWavData(id);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getTrans = () => async () => {
  try {
    const { data } = await AudioService.getTrans();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateStream = (streamId) => async () => {
  try {
    const { data } = await AudioService.updateStream(streamId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const searchByAudio = (text) => ({
  type: types.SEARCH_BY_AUDIO,
  payload: text,
});

export { getWavs, getAudio, getWavData, getTrans, updateStream, searchByAudio };
