const typography = {
  fontFamily: ["RedHatRegular"],
  regular: {
    fontFamily: ["RedHatRegular"],
    fontWeight: 400,
  },
  medium: {
    fontFamily: ["RedHatMedium"],
    fontWeight: 500,
  },
  bold: {
    fontFamily: ["RedHatBold"],
    fontWeight: 700,
  },
  h1: {
    fontFamily: ["RedHatBold"],
    fontWeight: 700,
  },
  h2: {
    fontFamily: ["RedHatBold"],
    fontWeight: 700,
  },
  h3: {
    fontFamily: ["RedHatBold"],
    fontWeight: 700,
  },
  h4: {
    fontFamily: ["RedHatMedium"],
    fontWeight: 500,
  },
  h5: {
    fontFamily: ["RedHatMedium"],
    fontWeight: 500,
  },
  h6: {
    fontFamily: ["RedHatMedium"],
    fontWeight: 500,
  },
};

export { typography };
