// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: 305,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    alignItems: "center",
  },
  timeline: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  duration: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  time: {
    fontSize: theme.typography.pxToRem(14),
  },
  currentTime: {
    display: "none",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  totalTime: {
    display: "none",
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
    },
  },
  controls: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    minHeight: 65,
    backgroundColor: "#eef2fe",
    borderRadius: 100,
    padding: "0 30px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(2),
      backgroundColor: "inherit",
    },
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  playPauseIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down(theme.breakpoints.values.small)]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  playIcon: {
    fontSize: "2.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
  },
  pauseIcon: {
    fontSize: "2.6rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
  },
  skipIcon: {
    width: 24,
    height: 14,
  },
  skipText: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  playControls: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      left: "auto",
      transform: "none",
    },
  },
  volume: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  volumeText: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
  },
  volumeIcon: {
    width: 24,
    height: 19,
  },
  volumeSlider: {
    width: 140,
  },
  speedIcon: {
    width: 24,
    height: 17,
    marginRight: 12,
  },
  speedButton: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  speedText: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
  },
  shareIcon: {
    flex: "0 0 22px",
    width: 22,
    height: 18,
    fill: theme.palette.primary.main,
    cursor: "pointer",
  },
  formControl: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  select: {
    marginLeft: theme.spacing(0.5),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontFamily: theme.typography.medium.fontFamily,
      fontSize: theme.typography.pxToRem(20),
    },
    "&:before, &:after": {
      display: "none",
    },
    "& .MuiSelect-select": {
      padding: 0,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-icon": {
      display: "none",
    },
  },
  downloadLinkWrapper: {
    position: "absolute",
    bottom: 13,
    right: 32,
    [theme.breakpoints.down("sm")]: {
      right: 16,
    },
  },
  downloadIcon: {
    marginRight: theme.spacing(0.5),
  },
  downloadLink: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export { useStyles };
