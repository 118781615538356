// Core
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy, useBlockLayout } from "react-table";

// Components
import { MuiTable } from "components/common";

// Hooks
import { useBreakpoints } from "hooks";

const MuiTableWrapper = ({ tableData, tableColumns, initialState }) => {
  const { isMobile } = useBreakpoints();
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);
  const defaultColumn = useMemo(
    () => ({
      width: 100,
    }),
    []
  );

  const tableDesktopInstance = useTable(
    { columns, data, initialState },
    useSortBy
  );

  const tableMobileInstance = useTable(
    { columns, data, initialState, defaultColumn },
    useSortBy,
    useBlockLayout
  );

  return (
    <>
      {isMobile ? (
        <MuiTable {...tableMobileInstance} />
      ) : (
        <MuiTable {...tableDesktopInstance} />
      )}
    </>
  );
};

MuiTableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  initialState: PropTypes.object,
};

export default MuiTableWrapper;
