// Service
import { ApiError } from "api";
import { AuthService } from "services";

// Types
import * as authTypes from "redux/actiontypes/auth-types";

const loginAction =
  ({ login, password }) =>
  async () => {
    try {
      const { data } = await AuthService.login(login, password);

      localStorage.setItem("token", data);

      return data;
    } catch (error) {
      const message = ApiError.getErrorMessage(error);
      throw new Error(message);
    }
  };

const logout = () => async (dispatch) => {
  try {
    await AuthService.logout();

    localStorage.removeItem("token");

    dispatch({ type: authTypes.LOGOUT });
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};

const setAuth = (bool) => ({
  type: authTypes.SET_AUTH,
  payload: bool,
});

const checkAuth = () => async () => {
  try {
    const { data } = await AuthService.checkAuth();

    localStorage.setItem("token", data);

    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

export { loginAction, logout, checkAuth, setAuth };
