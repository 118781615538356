// Core
import { makeStyles } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    marginBottom: theme.spacing(3),
    marginTop: 6,
  },
  breadcrumbItemRoot: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  breadcrumbItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "137px",
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    letterSpacing: 0.3,
    textTransform: "none",
  },
  secondaryButton: {
    marginRight: theme.spacing(2),
    backgroundColor: darken("#1FD76D", 0.1),
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: darken("#1FD76D", 0.3),
    },
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.main,
  },
  size: {
    marginLeft: theme.spacing(1),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
  },
  dateRow: {
    display: "flex",
    alignItems: "center",
  },
  calendarIcon: {
    marginRight: theme.spacing(0.5),
  },
  date: {
    marginRight: theme.spacing(0.3),
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.grey,
  },
  time: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.grey,
  },
  transcriptionsTitleWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  transcriptionsTitle: {
    marginRight: theme.spacing(1),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  transcriptionLabel: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.red.alert,
  },
  transcription: {
    height: 20,
    border: "none",
    borderRadius: 6,
    marginBottom: theme.spacing(1),
    textTransform: "lowercase",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.yellow.main,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export { useStyles };
