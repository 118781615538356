// Core
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { Button } from "@material-ui/core";

// Styles
import { useStyles } from "./button.styles";

const MuiButton = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.submit, className)}
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      {...rest}
    >
      {children}
    </Button>
  );
};

MuiButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MuiButton;
