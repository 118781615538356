// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiSnackbarContent-root": {
      minWidth: 540,
      borderRadius: 12,
      backgroundColor: "#373d49",
      boxShadow: "0px 4px 15px rgba(35, 41, 53, 0.42264)",
      [theme.breakpoints.down("sm")]: {
        minWidth: "auto",
        width: "100%",
      },
    },
    "& .MuiSnackbarContent-message": {
      letterSpacing: "0.15px",
    },
    "& .MuiSnackbarContent-action": {
      marginRight: 0,
      cursor: "pointer",
      "& span": {
        fontFamily: theme.typography.bold.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.common.white,
      },
    },
  },
}));

export { useStyles };
