// Core
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// Components
import { MuiTooltip } from "components/common";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    lineHeight: "15px",
  },
  tooltip: {
    flex: "0 0 auto",
    marginLeft: theme.spacing(0.5),
  },
  withoutTooltip: {
    marginRight: theme.spacing(0.5),
  },
}));

const MuiHeaderCell = ({ title, tooltipTitle }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.cell, tooltipTitle ? "" : classes.withoutTooltip)}
    >
      <span className={classes.title}>{title}</span>
      {tooltipTitle && (
        <MuiTooltip title={tooltipTitle} className={classes.tooltip} />
      )}
    </div>
  );
};

MuiHeaderCell.propTypes = {
  title: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
};

export default MuiHeaderCell;
