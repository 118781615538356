// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "12px",
    height: "12px",
    fill: theme.palette.text.main,
    opacity: "0.5",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      opacity: 1,
    },
  },
}));

export { useStyles };
