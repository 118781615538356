// Types
import * as types from "redux/actiontypes/audio-types";

const initialState = {
  searchByAudio: "",
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_BY_AUDIO:
      return {
        ...state,
        searchByAudio: action.payload,
      };

    default:
      return state;
  }
};

export { audioReducer };
