// Styles
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    position: "relative",
    paddingBottom: 20,
    marginBottom: 0,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: theme.palette.grey.light,

      "& fieldset": {
        borderColor: theme.palette.grey.border,
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
      "&.Mui-error fieldset": {
        borderColor: "#f44336",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.main,
    },
    "& .MuiInputLabel-root": {
      fontSize: theme.typography.pxToRem(14),
    },
    icon: {
      backgroundColor: "red",
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: 0,
      left: 0,
    },
  },
}));

export { useStyles };
