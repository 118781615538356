// Core
import React from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

// Components
import { Link } from "@material-ui/core";

// Icons
import { ReactComponent as ArrowLeftIcon } from "theme/icons/arrow-left.svg";

// Styles
import { useStyles } from "./back-button.styles";

const MuiBackButton = ({ to, previousRoute, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <>
      {previousRoute ? (
        <Link
          className={classes.button}
          onClick={goToPreviousPath}
          color="inherit"
          underline="none"
          {...rest}
        >
          <ArrowLeftIcon className={classes.icon} /> Back
        </Link>
      ) : (
        <Link
          className={classes.button}
          component={RouterLink}
          to={to}
          color="inherit"
          underline="none"
          {...rest}
        >
          <ArrowLeftIcon className={classes.icon} /> Back
        </Link>
      )}
    </>
  );
};

export default MuiBackButton;
