// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    paddingRight: 22,
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  head: {
    position: "sticky",
    top: 0,
    zIndex: 99,
    backgroundColor: theme.palette.common.white,
  },
  headCell: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.main,
    userSelect: "none",
    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  headCellRow: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  hiddenHeadCell: {
    width: 57.45,
  },
  bodyCell: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  sortIcon: {
    fontSize: "1rem",
    color: theme.palette.text.main,
  },
  defaultIcon: {
    opacity: 0.5,
  },
  tableRow: {
    position: "relative",
    "& .MuiTableCell-body": {
      "&:nth-last-child(2)": {
        paddingRight: 0,
      },
    },
  },
}));

export { useStyles };
