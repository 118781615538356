// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { showSnackbar } from "redux/actions/ui-actions";

// Components
import { Snackbar, Typography } from "@material-ui/core";

// Styles
import { useStyles } from "./snackbar.styles";

const MuiSnackbar = ({ message }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.snackbarIsOpen);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(showSnackbar(false));
  };

  const action = (
    <Typography
      className={classes.cancel}
      variant="caption"
      onClick={handleClose}
    >
      Cancel
    </Typography>
  );

  return (
    <Snackbar
      className={classes.root}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      message={message}
      action={action}
    />
  );
};

export default MuiSnackbar;
