// Core
import moment from "moment-timezone";
import parse from "html-react-parser";

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function getDateFromTimestamp(timestamp) {
  if (timestamp) {
    const dateParts = moment.parseZone(timestamp).format("MM/DD/YYYY");
    const timeParts = moment.parseZone(timestamp).format("hh:mm A");

    return { date: dateParts, time: timeParts };
  }

  return "";
}

function getAmpmFromTimestamp(timestamp) {
  if (timestamp) {
    const date = moment(timestamp).format("MM/DD");
    const time = moment(timestamp).format("hh:mm A");

    return `${date} ${time}`;
  }
}

function getAmpmWithTimezone(timestamp, timezone) {
  if (timestamp && timezone) {
    const date = moment(timestamp).tz(timezone).format("MM/DD");
    const time = moment(timestamp).tz(timezone).format("hh:mm A");

    return `${date} ${time}`;
  }
}

function secToMinutes(timeInSeconds) {
  const pad = function (num, size) {
    return ("000" + num).slice(size * -1);
  };
  let time = parseFloat(timeInSeconds).toFixed(3);
  let minutes = Math.floor(time / 60) % 60;
  let seconds = Math.floor(time - minutes * 60);

  return pad(minutes, 2) + ":" + pad(seconds, 2);
}

function formatDate(timestamp) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(timestamp);
  let day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  day = day < 10 ? `0${day}` : day;

  return `${day} ${month}, ${year}`;
}

function searchBy(values, filterBy) {
  const normalizedValue = (value) =>
    value.toLowerCase().trim().split(" ").join("");
  const normalizedFilterBy = normalizedValue(filterBy);

  if (values) {
    return Object.keys(values).some(
      (key) =>
        values[key] && normalizedValue(values[key]).includes(normalizedFilterBy)
    );
  }
}

function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFirstLettersFromUserName({ first_name, last_name }) {
  if (first_name && last_name) {
    return `${first_name.charAt(0).toUpperCase()}${last_name
      .charAt(0)
      .toUpperCase()}`;
  }
}

function prettyDuration(duration) {
  const sec = parseInt(duration, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}h`;
}

const formatEntities = (item) => {
  const { text, entities } = item;

  entities.sort((a, b) => (a.start < b.start ? -1 : 1));

  let formattedString = "";
  let cur = 0;

  entities.forEach(({ start, end, type }) => {
    if (start > cur) {
      formattedString += text.slice(cur, start - 1).trim() + " ";
    }
    formattedString += `<span class="${type}">${text
      .slice(Math.max(0, start - 1), end + 1)
      .trim()}</span> `;
    cur = end + 1;
  });

  formattedString += text.slice(cur).trim();

  return parse(formattedString);
};

function appHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

function addClassToBody(className) {
  document.body.classList.add(className);
}

function removeClassFromBody(className) {
  document.body.classList.remove(className);
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export {
  isEmpty,
  secToMinutes,
  formatDate,
  searchBy,
  capitalizeString,
  getDateFromTimestamp,
  getAmpmFromTimestamp,
  getAmpmWithTimezone,
  getFirstLettersFromUserName,
  prettyDuration,
  formatEntities,
  appHeight,
  addClassToBody,
  removeClassFromBody,
  formatBytes,
};
