// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3.5),
  },
  titleIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1.5),
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.text.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  table: {
    width: "100%",
  },
  tableWrapper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "22px 0 22px 22px",
    borderRadius: 8,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    "& .MuiTableContainer-root": {
      borderRadius: 8,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: "16px 0 16px 16px",
      "& .MuiTableContainer-root": {
        borderRadius: 0,
      },
    },
  },
  searchRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
    paddingRight: 22,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  search: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export { useStyles };
