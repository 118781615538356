// Core
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import CssBaseline from "@material-ui/core/CssBaseline";

// Store
import { store } from "redux/store";

// Components
import { App } from "components";

// Tools
import reportWebVitals from "reportWebVitals";

// Theme
import theme from "theme/theme.js";
import "theme/init.scss";

const queryClient = new QueryClient();

render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root")
);

reportWebVitals();
