// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  paper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centered: {
    justifyContent: "center",
  },
  noContainer: {
    padding: 0,
    margin: 0,
  },
}));

export { useStyles };
