// Core
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { Container } from "@material-ui/core";

// Styles
import { useStyles } from "./wrapper.styles";

const MuiWrapper = ({ children, maxWidth, isMobile, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Container
      maxWidth={maxWidth}
      className={clsx(
        classes.container,
        isMobile ? classes.mobile : "",
        className
      )}
      {...rest}
    >
      {children}
    </Container>
  );
};

MuiWrapper.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.string.isRequired,
};

export default MuiWrapper;
