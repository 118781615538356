// Core
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import { Tooltip, useMediaQuery } from "@material-ui/core";

// Icons
import { ReactComponent as TooltipIcon } from "theme/icons/tooltip.svg";

// Styles
import { useStyles } from "./tooltip.styles.js";

const MuiTooltip = ({ className = "", title, ...rest }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (isMobile) {
      e.stopPropagation();
      setOpen(!open);
    }
  };

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  return (
    <Tooltip
      className={className}
      onClick={handleClick}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      title={title}
      placement="top"
      leaveTouchDelay={4000}
      arrow
      {...rest}
    >
      <TooltipIcon className={classes.icon} />
    </Tooltip>
  );
};

MuiTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MuiTooltip;
