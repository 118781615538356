// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    cursor: "pointer",
  },
  icon: {
    width: 12,
    height: 11,
    marginRight: theme.spacing(1),
  },
}));

export { useStyles };
