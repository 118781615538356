// Core
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";

// Icons
import { Search } from "@material-ui/icons";

// Styles
import { useStyles } from "./search.styles";

const MuiSearch = ({ className = "", onChange, value, placeholder }) => {
  const classes = useStyles();

  const handleChange = (event) => onChange(event.target.value);

  return (
    <FormControl className={clsx(classes.root, className)} variant="outlined">
      <OutlinedInput
        type="text"
        className={classes.input}
        value={value}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              aria-label="search stores"
              edge="start"
              className={classes.button}
            >
              <Search className={classes.icon} />
            </IconButton>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
    </FormControl>
  );
};

MuiSearch.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default MuiSearch;
