// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  mobile: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
}));

export { useStyles };
