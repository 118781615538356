// Core
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { Catcher } from "components/common";
import { Container } from "@material-ui/core";

// Styles
import { useStyles } from "./form.styles";

const MuiForm = ({
  children,
  maxWidth = "xs",
  centered,
  noContainer,
  className,
}) => {
  const classes = useStyles();

  return (
    <Catcher>
      <Container
        className={clsx(
          classes.root,
          noContainer ? classes.noContainer : "",
          className
        )}
        maxWidth={maxWidth}
      >
        <div className={clsx(classes.paper, centered ? classes.centered : "")}>
          {children}
        </div>
      </Container>
    </Catcher>
  );
};

MuiForm.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
};

export default MuiForm;
