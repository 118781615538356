// Types
import * as types from "redux/actiontypes/auth-types";

const initialState = {
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case types.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export { authReducer };
