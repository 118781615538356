// Core
import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import * as Yup from "yup";

// Actions
import { loginAction, setAuth } from "redux/actions/auth-actions";

// Components
import { Avatar, Typography } from "@material-ui/core";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiCopyright,
  MuiLoader,
} from "components/common";
import { Alert } from "@material-ui/lab";

// Icons
import { LockOutlined } from "@material-ui/icons";

// Styles
import { useStyles } from "./login.styles";

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    username: "",
    password: "",
  };
  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const { error, isLoading, isError, mutate } = useMutation(
    ({ login, password }) => dispatch(loginAction({ login, password })),
    {
      onSuccess: (data) => {
        dispatch(setAuth(true));
        history.push("/");
      },
    }
  );

  const onSubmit = (values) => {
    const { username, password } = values;
    const normalizedUsername = username.trim().toLowerCase();
    mutate({ login: normalizedUsername, password });
  };

  return (
    <MuiForm centered>
      <Avatar className={classes.avatar}>
        <LockOutlined className={classes.icon} />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>

      {isError && <Alert severity="error">{error.message}</Alert>}

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className={classes.form} noValidate autoComplete="off">
            <MuiInput type="text" name="username" label="Username" required />
            <MuiInput
              type="password"
              name="password"
              label="Password"
              required
            />
            <MuiButton disabled={isLoading}>
              {isLoading ? <MuiLoader /> : "Sign In"}
            </MuiButton>
          </Form>
        )}
      </Formik>
      <MuiCopyright />
    </MuiForm>
  );
};

export default LoginForm;
