// Core
import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

// Components
import { MuiEmptySearch } from "components/common";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { UnfoldMore, ArrowDownward, ArrowUpward } from "@material-ui/icons";

// Styles
import { useStyles } from "./table.styles";

const MuiTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  data,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { isMobile } = useBreakpoints();

  const handleRowClick = (id) => {
    if (isMobile) {
      history.push(`/audio/${id}`);
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table {...getTableProps()} className={classes.table}>
        <TableHead className={classes.head}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  className={classes.headCell}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                >
                  <div
                    className={clsx(
                      classes.headCellRow,
                      column.hideHeadCell ? classes.hiddenHeadCell : ""
                    )}
                  >
                    {!column.hideHeadCell && (
                      <>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownward className={classes.sortIcon} />
                          ) : (
                            <ArrowUpward className={classes.sortIcon} />
                          )
                        ) : (
                          <UnfoldMore
                            className={clsx(
                              classes.sortIcon,
                              classes.defaultIcon
                            )}
                          />
                        )}
                      </>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                className={classes.tableRow}
                onClick={() => handleRowClick(row.original.wav)}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      className={classes.bodyCell}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!data.length ? <MuiEmptySearch text="Not found" /> : null}
    </TableContainer>
  );
};

export default MuiTable;
