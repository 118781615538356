// Core
import React from "react";
import PropTypes from "prop-types";

// Components
import { CircularProgress } from "@material-ui/core";

// Styles
import { useStyles } from "./loader.styles";

const MuiLoader = ({
  size = "30px",
  color = "inherit",
  fullpage = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <CircularProgress
      size={size}
      color={color}
      className={fullpage ? classes.fullpage : ""}
      {...rest}
    />
  );
};

MuiLoader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  fullpage: PropTypes.bool,
};

export default MuiLoader;
