// Core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";

// Actions
import { checkAuth, setAuth } from "redux/actions/auth-actions";

// Routes
import { getRoutes } from "routes";

// Components
import { MuiLoader } from "components/common";

// Tools
import { appHeight } from "utils/helpers";

const App = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const { isLoading } = useQuery(["check_auth"], () => dispatch(checkAuth()), {
    onSuccess: (data) => {
      dispatch(setAuth(true));
    },
    onError: (error) => {
      dispatch(setAuth(false));
    },
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!localStorage.getItem("token"),
  });

  useEffect(() => {
    window.addEventListener("resize", appHeight);
    appHeight();
  }, []);

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  return (
    <main className="viewportHeight" role="main">
      {getRoutes(isAuthenticated)}
    </main>
  );
};

export default App;
