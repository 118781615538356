// Core
import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { logout } from "redux/actions/auth-actions";

// Components
import { AppBar, Toolbar, IconButton } from "@material-ui/core";

// Icons
import { ReactComponent as LogoIcon } from "theme/icons/logo.svg";
import { ReactComponent as LogoutIcon } from "theme/icons/logout.svg";

// Styles
import { useStyles } from "./header.styles";

const MuiHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogout = () => dispatch(logout());

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.row}>
        <LogoIcon className={classes.logo} />
        <IconButton
          className={classes.logoutBtn}
          onClick={handleLogout}
          color="primary"
          aria-label="Logout"
        >
          <LogoutIcon className={classes.logoutIcon} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default MuiHeader;
