// Core
import { combineReducers } from "redux";

// Reducers
import { authReducer as auth } from "./auth-reducer";
import { audioReducer as audio } from "./audio-reducer";
import { uiReducer as ui } from "./ui-reducer";

const rootReducer = combineReducers({
  auth,
  audio,
  ui,
});

export { rootReducer };
