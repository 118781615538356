// Core
import React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import {
  getAudio,
  getWavData,
  getTrans,
  updateStream,
} from "redux/actions/audio-actions";

// Components
import { Waveplayer } from "components";
import {
  Catcher,
  MuiWrapper,
  MuiLoader,
  MuiBackButton,
  MuiSnackbar,
} from "components/common";
import {
  Breadcrumbs,
  Link,
  Typography,
  Button,
  Chip,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Icons
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";
import { NavigateNext, Refresh, Settings } from "@material-ui/icons";

// Hooks
import { useBreakpoints } from "hooks";

// Utils
import { getDateFromTimestamp, formatBytes } from "utils/helpers";

// Styles
import { useStyles } from "./audio.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";
import { showSnackbar } from "redux/actions/ui-actions";

const Audio = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { isMobile, isDesktop } = useBreakpoints();

  const getAudioQuery = useQuery(["audio", id], () => dispatch(getAudio(id)), {
    refetchOnWindowFocus: false,
  });

  const getWavQuery = useQuery(
    ["wavdata", id],
    () => dispatch(getWavData(id)),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getTransQuery = useQuery(["trans", id], () => dispatch(getTrans()), {
    refetchOnWindowFocus: false,
  });

  const updateStreamQuery = useMutation(() => dispatch(updateStream(id)), {
    onSuccess: (data) => {
      dispatch(showSnackbar(true));
      queryClient.invalidateQueries(["trans", id]);
    },
  });

  const handleRefreshClick = () => {
    queryClient.invalidateQueries(["audio", id]);
    queryClient.invalidateQueries(["wavdata", id]);
    queryClient.invalidateQueries(["trans", id]);
  };

  const handleTranscribeClick = () => {
    if (id) {
      updateStreamQuery.mutate(id);
    }
  };

  if (getAudioQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getAudioQuery.isError) {
    return <Alert severity="error">{getAudioQuery.error.message}</Alert>;
  }

  return (
    <Catcher>
      <MuiWrapper maxWidth="lg" isMobile={isMobile}>
        <div
          className={clsx(isMobile ? globalClasses.container : classes.root)}
        >
          {isDesktop && (
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumb}
              separator={<NavigateNext fontSize="small" />}
            >
              <Link
                className={classes.breadcrumbItemRoot}
                component={RouterLink}
                to={`/audios`}
              >
                Audios
              </Link>
              <Typography className={classes.breadcrumbItem}>
                {getWavQuery.data?.wav}
              </Typography>
            </Breadcrumbs>
          )}

          {isMobile && (
            <div className={classes.buttonWrapper}>
              <MuiBackButton className={classes.backButton} to="/audios" />
              <Box>
                <Button
                  variant="contained"
                  color="inherit"
                  size="medium"
                  onClick={handleTranscribeClick}
                  className={clsx(classes.button, classes.secondaryButton)}
                  startIcon={<Settings />}
                  disabled={`${getTransQuery?.data?.stream}.wav` === id}
                >
                  {`${getTransQuery?.data?.stream}.wav` === id
                    ? "Transcribing..."
                    : "Transcribe"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleRefreshClick}
                  className={classes.button}
                  startIcon={<Refresh />}
                >
                  Refresh
                </Button>
              </Box>
            </div>
          )}

          <div className={classes.headingWrapper}>
            <div className={classes.heading}>
              <Typography
                className={classes.title}
                variant="h5"
                color="inherit"
              >
                {getWavQuery.data?.wav}
                <span className={classes.size}>
                  ({formatBytes(getWavQuery.data?.size)})
                </span>
              </Typography>
              <div className={classes.dateRow}>
                <CalendarIcon className={classes.calendarIcon} />
                <Typography className={classes.date} variant="caption">
                  {getDateFromTimestamp(getWavQuery.data?.timestamp).date} at
                </Typography>
                <Typography className={classes.time} variant="caption">
                  {getDateFromTimestamp(getWavQuery.data?.timestamp).time}
                </Typography>
              </div>
            </div>

            {isDesktop && (
              <Box>
                <Button
                  variant="contained"
                  color="inherit"
                  size="large"
                  onClick={handleTranscribeClick}
                  className={clsx(classes.button, classes.secondaryButton)}
                  startIcon={<Settings />}
                  disabled={`${getTransQuery?.data?.stream}.wav` === id}
                >
                  {`${getTransQuery?.data?.stream}.wav` === id
                    ? "Transcribing..."
                    : "Transcribe"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleRefreshClick}
                  className={classes.button}
                  startIcon={<Refresh />}
                >
                  Refresh
                </Button>
              </Box>
            )}
          </div>

          {getAudioQuery.data && (
            <Waveplayer
              url={getAudioQuery.data.audio64}
              timestamp={getWavQuery?.data?.timestamp}
            />
          )}

          {`${getTransQuery?.data?.stream}.wav` === id &&
            getTransQuery?.data?.trans.length > 0 && (
              <>
                <Box mb={1.5}>
                  <Box className={classes.transcriptionsTitleWrapper}>
                    <Typography
                      className={classes.transcriptionsTitle}
                      variant="h5"
                      color="inherit"
                    >
                      Transcriptions
                    </Typography>
                    {getWavQuery.data?.timestamp >
                      getTransQuery.data?.timestamp && (
                      <Typography
                        className={classes.transcriptionLabel}
                        variant="caption"
                        color="inherit"
                      >
                        [old]
                      </Typography>
                    )}
                  </Box>

                  <div className={classes.dateRow}>
                    <CalendarIcon className={classes.calendarIcon} />
                    <Typography className={classes.date} variant="caption">
                      {getDateFromTimestamp(getTransQuery.data?.timestamp).date}{" "}
                      at
                    </Typography>
                    <Typography className={classes.time} variant="caption">
                      {getDateFromTimestamp(getTransQuery.data?.timestamp).time}
                    </Typography>
                  </div>
                </Box>

                <div className={classes.transcriptions}>
                  {getTransQuery?.data?.trans
                    ?.split("\n")
                    ?.map((item, index) => (
                      <div key={index}>
                        <Chip
                          className={classes.transcription}
                          variant="outlined"
                          size="small"
                          label={item}
                        />
                        <br />
                      </div>
                    ))}
                </div>
              </>
            )}

          {updateStreamQuery.isSuccess && (
            <MuiSnackbar message={`Transcription of ${id} is started!`} />
          )}
        </div>
      </MuiWrapper>
    </Catcher>
  );
};

export default Audio;
