// Core
import { $http } from "api";

class AudioService {
  static async getWavs() {
    return await $http.get("/wavs");
  }

  static async getAudio(id) {
    return await $http.get(`/audio/${id}`);
  }

  static async getWavData(id) {
    return await $http.get(`/metadata/${id}`);
  }

  static async getTrans() {
    return await $http.get("/trans");
  }

  static async updateStream(streamId) {
    return await $http.post("/stream", {
      stream: streamId.split(".")[0],
    });
  }
}

export default AudioService;
