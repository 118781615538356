// Core
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Pages
import { Audios, Audio, Login } from "pages";

// Custom Routes
import { MainRoute } from "routes";

const getRoutes = (isAuthenticated) => {
  if (isAuthenticated) {
    return (
      <Switch>
        <MainRoute exact path="/" component={Audios} />
        <MainRoute exact path="/audio/:id" component={Audio} />
        <Redirect to="/" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default getRoutes;
