import { useMediaQuery } from "@material-ui/core";

function useBreakpoints() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return { isMobile, isDesktop };
}

export default useBreakpoints;
