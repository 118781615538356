// Core
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { MuiTableWrapper } from "components/common";

// Tools
import { searchBy } from "utils/helpers";

const MuiTableContainer = ({
  tableColumns,
  tableData,
  initialState,
  searchValue = "",
  searchFields = [],
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (tableData) {
      const filteredData = tableData.filter((obj) => {
        if (searchFields.length > 0) {
          let formattedObj = {};
          searchFields.map((field) => {
            return (formattedObj[field] = obj[field]);
          });
          return searchBy(formattedObj, searchValue);
        }
        return obj;
      });

      return searchValue.length > 0 && searchFields.length > 0
        ? setData(filteredData)
        : setData(tableData);
    }
  }, [searchValue, searchFields, tableData]);

  return (
    <>
      {data && (
        <MuiTableWrapper
          tableData={data}
          tableColumns={tableColumns}
          initialState={initialState}
        />
      )}
    </>
  );
};

MuiTableContainer.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  searchValue: PropTypes.string,
  searchFields: PropTypes.array,
};

export default MuiTableContainer;
