// Styles
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1",
    minWidth: "150px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.grey.border,
        borderWidth: 1,
      },
    },
  },
  input: {
    height: 45,
    borderRadius: 8,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    backgroundColor: theme.palette.grey.light,
    "& .MuiOutlinedInput-input": {
      paddingTop: 14,
      paddingBottom: 14,
      paddingRight: 14,
    },
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  button: {
    paddingRight: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

export { useStyles };
