// Core
import { createTheme } from "@material-ui/core/styles";
import { palette } from "./palette";
import { typography } from "./typography";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      small: 360,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography,
  palette,
});

theme.props = {
  MuiIconButton: { disableRipple: true, disableFocusRipple: true },
};

theme.overrides = {
  MuiAlert: {
    root: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderRadius: 8,
    },
  },
  MuiTableCell: {
    head: {
      position: "relative",
    },
  },
  MuiMenu: {
    paper: {
      width: "195px",
    },
  },
  MuiTooltip: {
    tooltip: {
      padding: 11,
      fontFamily: theme.typography.regular.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      backgroundColor: "#444851",
      borderRadius: 8,
    },
    arrow: {
      color: "#444851",
    },
  },
  MuiPopover: {
    paper: {
      borderRadius: 8,
      boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.144231)",
    },
  },
};

export default theme;
