// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    left: "50%",
    transform: "translateX(-50%)",
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      display: "none",
    },
  },
}));

export { useStyles };
