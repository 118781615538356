// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 42,
    borderRadius: 8,
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    letterSpacing: "0.15px",
  },
}));

export { useStyles };
