// Core
import { $http } from "api";

class AuthService {
  static async login(login, password) {
    return $http.post("/login", { login, password });
  }

  static async logout() {
    return $http.get("/logout");
  }

  static async checkAuth() {
    return $http.get("/refresh", { withCredentials: true });
  }
}

export default AuthService;
